import React from "react"
import Layout from "../components/Layout"
import "../css/projects/web-project/webprojectcard.css"
import Particle12 from "../components/Particle12"
import SEO from "../components/SEO"
import { Link } from "gatsby"

const linkedlist = () => {
  return (
    <div>
      <Layout>
        <SEO
          title="Linked List Programs"
          description="Programs of Linked List under Data Structures and Algorithm with Python."
        />
        <Particle12></Particle12>
        <h6>s</h6>
        <div className="programming-project">
          <h1>Linked List</h1>

          <section className="noSelect">
            <Link to="/dsa/">
              <button type="button" className="back-btn noSelect">
                <span>{"<"}= More Programs</span>
              </button>
            </Link>
          </section>
        </div>

        <section className="most-liked noSelect">
          <h3 className="total">Total Programs :- 7</h3>
          <div className="row">
            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Linked List Traversal</h3>
                </div>
                <p className="basic-op">
                  •Create a node, with data and next
                  <br />
                  •Input data in the head part
                  <br />
                  •Set next to head of another node
                  <br />
                  •Print the List
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Linked%20List/Linked%20List%20Traversal.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">
                    Static Representation of Linked List
                  </h3>
                </div>
                <p className="basic-op">
                  •Create a node
                  <br />
                  •Make function to access List
                  <br />
                  •Set next to head of another node
                  <br />
                  •Print the List
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Linked%20List/Static%20Representation%20of%20%20Linked%20List.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">
                    Dynamic Representation of Linked List
                  </h3>
                </div>
                <p className="basic-op">
                  •Create a node, with data and next
                  <br />
                  •Use of pointer is involved
                  <br />
                  •Set next to head of another node
                  <br />
                  •Print the List
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Linked%20List/Dynamic%20Representation%20of%20Linked%20List.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">
                    Different Operations on a Single linked list
                  </h3>
                </div>
                <p className="basic-op">
                  •Create a node
                  <br />
                  •Insert at beginning
                  <br />
                  •Insert at after
                  <br />
                  •Insert at end
                  <br />
                  •Delete node
                  <br />
                  •Print List
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Linked%20List/Different%20Operations%20on%20a%20Single%20linked%20list.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Reverse a linked list</h3>
                </div>
                <p className="basic-op">
                  •Create a node
                  <br />
                  •Push items
                  <br />
                  •Make reverse function
                  <br />
                  •Print it
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Linked%20List/Reverse%20a%20linked%20list.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Circular Linked List</h3>
                </div>
                <p className="basic-op">
                  •Create a node
                  <br />
                  •nodes must connect to form a circle
                  <br />
                  •Push items
                  <br />
                  •Print it
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Linked%20List/Circular%20Linked%20List.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Doubly Linked List </h3>
                </div>
                <p className="basic-op">
                  •Create a node
                  <br />
                  •Make three parts of node as data, next, prev
                  <br />
                  •Push items
                  <br />
                  •Print it
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Linked%20List/Doubly%20Linked%20List%20.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  )
}

export default linkedlist
